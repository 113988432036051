import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Column.module.scss';

const Item = React.memo(({ className, childClassName, children, ...props }) => {
  return (
    <div className={classNames(Styles.item, className)}>
      <div className={classNames(Styles.itemChild, childClassName)} {...props}>
        {children}
      </div>
    </div>
  );
});

Item.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Item.defaultProps = {
  className: '',
};
export default Item;
