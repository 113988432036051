import React from 'react';
import Link from '../../Link';
import classNames from 'classnames';
import { Types } from '../constants';
import Styles from '../Header.module.scss';

export const DefaultLinks = ({ children }) => {
  return (
    <div className={Styles.navList}>
      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/clients'>
          {`Clients`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/enterprise'>
          {`Enterprise`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/blog'>
          {`Blog`}
        </Link>
      </div>

      <div className={classNames(Styles.navItem, Styles.leftBorder)} id='login-action'>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/login'>
          {`Log In`}
        </Link>
      </div>
      {/*CTA COES HERE*/}
      <div className={Styles.navItem}>{children}</div>
    </div>
  );
};

export const TalentLinks = ({ children }) => {
  return (
    <div className={Styles.navList}>
      {/* all talents page except talents/faq  */}
      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/talents/faq'>
          {`FAQ`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link className={Styles.navLink} activeClassName={Styles.activeNavItem} title='' href='/'>
          {`Go to ${process.env.NEXT_PUBLIC_SITE_NAME}`}
        </Link>
      </div>
      <div className={classNames(Styles.navItem, Styles.leftBorder)} id='login-action'>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/login'>
          {`Log In`}
        </Link>
      </div>
      <div className={Styles.navItem}>{children}</div>
    </div>
  );
};
