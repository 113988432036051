import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './SkillSet.module.scss';

function SkillSet({ className, skills, style, count, center }) {
  if (!skills || !skills.length) return null;

  const endIndex = (count && count > skills.length ? skills.length : count) || skills.length;
  const extractedSkills = skills.slice(0, endIndex).map((skill) => {
    return skill?.skillDisplayInfo?.label || skill;
  });

  return (
    <div
      className={classNames(Styles.container, className, center && Styles.centered)}
      style={style}>
      {extractedSkills.map((skill, j) => (
        <div key={j} className={Styles.item}>
          {skill}
        </div>
      ))}
    </div>
  );
}

SkillSet.propTypes = {
  className: PropTypes.string,
  skills: PropTypes.array,
  count: PropTypes.number,
  style: PropTypes.object,
  center: PropTypes.bool,
};

export default SkillSet;
