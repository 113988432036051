import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Styles from './UserSlider.module.scss';

const Item = dynamic(() => import('./Item'));

const Container = ({ items, isMobile, bottom, className }) => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile]);

  return (
    <React.Fragment>
      <div className={classNames(mobile ? Styles.scroll : Styles.portfolioList)}>
        {items.map((item, index) => (
          <Item key={index} item={item} />
        ))}
        {mobile && <div style={{ flex: '0 0 1px' }} />}
      </div>
      {mobile && <div className={Styles.removeScroll} />}
    </React.Fragment>
  );
};

Container.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  bottom: PropTypes.bool,
  className: PropTypes.string,
};

export default Container;
