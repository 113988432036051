import React from 'react';
import Img from '../../Img';
import { Section, SectionContainer } from '../../Section';
import Column from '../../Column/Column';

const WhyWork = () => {
  return (
    <SectionContainer type={SectionContainer.Type.LIGHTBLUE_GRADIENT} anchor='why'>
      <Section
        title={`Why the highest performing companies work with ${process.env.NEXT_PUBLIC_SITE_NAME}`}
        subtitle={`${process.env.NEXT_PUBLIC_SITE_NAME} works with the most forward thinking companies globally, from Fortune 500 enterprises to fast-growing businesses.`}>
        <Column.List>
          <Column.Item>
            <Column.Image type={Column.Image.Type.SMALLER_ICON}>
              <Img
                height={50}
                title='specialist writers'
                alt='find specialist writers'
                src={`/images/whywork/rigorous-selection.svg`}
              />
            </Column.Image>
            <Column.Headline>{`Rigorous screening`}</Column.Headline>
            <Column.Content>
              {`Each talent is screened thoroughly for their track record and subject expertise. Only the top decile who apply are accepted.`}
            </Column.Content>
          </Column.Item>
          <Column.Item>
            <Column.Image type={Column.Image.Type.SMALLER_ICON}>
              <Img
                height={50}
                title='On-demand'
                alt='On demand'
                src={`/images/whywork/click.svg`}
              />
            </Column.Image>
            <Column.Headline>{`On-demand`}</Column.Headline>
            <Column.Content>
              {`Hire your talents exactly when you need them. Hire and scale your
                                          team without interrupting project timelines.`}
            </Column.Content>
          </Column.Item>
          <Column.Item>
            <Column.Image type={Column.Image.Type.SMALLER_ICON}>
              <Img
                height={50}
                title='Domain expertise'
                alt='Domain experts'
                src={`/images/whywork/light.svg`}
              />
            </Column.Image>
            <Column.Headline>{`Domain expertise`}</Column.Headline>
            <Column.Content>
              {`Access talents with the track record, skillsets and domain expertise to take your project to the next level.`}
            </Column.Content>
          </Column.Item>
          <Column.Item>
            <Column.Image type={Column.Image.Type.SMALLER_ICON}>
              <Img
                height={50}
                title='Cost effective'
                alt='Cost effective'
                src={`/images/whywork/growth.svg`}
              />
            </Column.Image>
            <Column.Headline>{`Cost effective`}</Column.Headline>
            <Column.Content>
              {`Say goodbye to excessive overheads. Reduce marketing costs by up to 70%.`}{' '}
            </Column.Content>
          </Column.Item>
          <Column.Item>
            <Column.Image type={Column.Image.Type.SMALLER_ICON}>
              <Img
                height={50}
                title='Risk-free'
                alt='Risk-free'
                src={`/images/whywork/thumbs.svg`}
              />
            </Column.Image>
            <Column.Headline>{`Risk-free`}</Column.Headline>
            <Column.Content>
              {
                "It's entirely risk-free. We provide a suite of options to guarantee you quality talents and project satisfaction."
              }
            </Column.Content>
          </Column.Item>
          <Column.Item>
            <Column.Image type={Column.Image.Type.SMALLER_ICON}>
              <Img
                height={50}
                title='Easy communication'
                alt='Easy communication'
                src={`/images/whywork/communication.svg`}
              />
            </Column.Image>
            <Column.Headline>{`Easy communication`}</Column.Headline>
            <Column.Content>
              {`Communicate with our talents as if they are a part of your core team, with tools and resources to ensure seamless workflow.`}
            </Column.Content>
          </Column.Item>
        </Column.List>
      </Section>
    </SectionContainer>
  );
};

export default WhyWork;
