import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Styles from './Grid.module.scss';

const GridItem = ({ className, children, ...props }) => {
  return (
    <div className={classNames(Styles.item, className)} {...props}>
      {children}
    </div>
  );
};

GridItem.propTypes = {
  style: PropTypes.object,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default GridItem;
