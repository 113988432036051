import React from 'react';
import Styles from './Grid.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GridItem from './GridItem';

export const gridStyles = Styles;

const Grid = ({ columns = 3, gutter = 0, className, children }) => {
  const childElements = React.Children.map(children, (child) => {
    if (!child) return null;
    const flexBasis = 100 / columns;
    const { style, ...restProps } = child.props;
    return React.cloneElement(child, {
      style: {
        flexBasis: `calc(${flexBasis}% - ${gutter}px)`,
        width: `calc(${flexBasis}% - ${gutter}px)`,
        ...style,
      }, // merge pre computed flex basis and width styles with the new styles supplied
      ...restProps,
    });
  });

  return <div className={classNames(Styles.container, className)}>{childElements}</div>;
};

Grid.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.number,
  gutter: PropTypes.number,
  children: PropTypes.node,
};

Grid.Styles = gridStyles;
Grid.Item = GridItem;

export default Grid;
