import React from 'react';
import PropTypes from 'prop-types';
import { Section, SectionContainer } from '../../Section';
import PressSlider from '../../Press/PressSlider/PressSlider';

const PressSection = ({ title, light }) => {
  return (
    <SectionContainer
      type={light ? SectionContainer.Type.LIGHTBLUE : SectionContainer.Type.DARKBLUE}>
      <Section
        title={title}
        variation={light ? Section.Variation.CENTER : Section.Variation.WHITE_CENTER}
        style={{ maxWidth: '1260px' }}>
        <PressSlider light={light} />
      </Section>
    </SectionContainer>
  );
};
PressSection.propTypes = {
  title: PropTypes.string,
  light: PropTypes.bool,
};
PressSection.defaultProps = {
  light: false,
  title: 'In the Press',
};
export default PressSection;
