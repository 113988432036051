import React from 'react';
import Styles from './PressSlider.module.scss';
import Direction from './img/arrow.inline.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const Types = {
  DARK: Styles.dark,
  LIGHT: Styles.light,
};

export function NextArrow(props) {
  const { className, style, onClick, type } = props;
  return (
    <Direction
      className={classNames(className, Styles.arrowNext, type)}
      style={{ ...style, display: 'block' }}
      alt='next arrow'
      onClick={onClick}
    />
  );
}

NextArrow.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export function PrevArrow(props) {
  const { className, style, onClick, type } = props;
  return (
    <Direction
      className={classNames(className, Styles.arrowPrev, type)}
      style={{ ...style, display: 'block' }}
      alt='previous arrow'
      onClick={onClick}
    />
  );
}

PrevArrow.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};
