import Logo from '../Logo/Logo';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Default from './components/Default';
import Talents from './components/Talents';
import Insights from './components/Insights';
import Enterprise from './components/Enterprise';

import { Types, Variants } from './constants';

import Styles from './Header.module.scss';

/*
  There are two types of heroes. One for hero pages (dark background) and pages (light background)
  There are several variants of pages
    - talents
    - blog (insights)
    - enterprise
    - default
*/
// export const Types = {
//   HERO: Styles.hero,
//   PAGES: Styles.pages,
// };

const Menu = ({ variant, ...props }) => {
  switch (variant) {
    case Variants.TALENT:
      return <Talents {...props} />;
    case Variants.INSIGHTS:
      return <Insights {...props} />;
    case Variants.ENTERPRISE:
      return <Enterprise {...props} />;
    case Variants.DEFAULT:
      return <Default {...props} />;
    default:
      return <Default {...props} />;
  }
};

// We memoize the menu in order to prevent it from re-rendering when type is changed
const MemoMenu = React.memo(Menu);

const Header = React.memo(
  ({ type: initialType, variant, buttonLabel, menuType, analyticsData }) => {
    const [sticky, setSticky] = useState(false);
    const [type, setType] = useState(initialType);
    const [ref, inView, entry] = useInView({ threshold: 1 });

    useEffect(() => {
      if (entry) {
        setSticky(!inView);
        setType(inView ? initialType : Types.LIGHT);
      }
    }, [inView, initialType, entry]);

    return (
      <React.Fragment>
        <div ref={ref} />
        <header className={classNames(type, Styles.container, sticky && Styles.sticky)}>
          <div className={Styles.wrapper}>
            <div className={Styles.header}>
              <MemoMenu {...{ variant, analyticsData, buttonLabel }} />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
);

Header.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.number,
  menuType: PropTypes.string,
  buttonLabel: PropTypes.string,
  analyticsData: PropTypes.object,
};

Header.defaultProps = {
  type: Types.DARK,
  variant: Variants.DEFAULT,
};

Header.Variants = Variants;
Header.Styles = Styles;
Header.Types = Types;
Header.LogoColor = Logo.Type;

// Deprecated
Header.Type = Types;

export default Header;
