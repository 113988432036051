import Cookies from 'universal-cookie';
import { request } from '../../../shared/apis/utils';
const cookies = new Cookies();

export const intercomHidden = () => {
  const intercomElement = document.querySelector('#intercom-container');
  if (intercomElement) {
    intercomElement.classList.add('intercomHidden');
  }
};

export const validateCorporateEmail = (email) => {
  if (
    /^([\w.+-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/.test(
      email
    )
  ) {
    return {};
  }
  return { corporateEmail: false };
};

export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return validateCorporateEmail(email);
  }
  return { emailError: true };
};

export const validatePhone = (phone) => {
  if (/^[\+\d]?(?:[\+\d-.,#,*"\s()]*)$/.test(phone)) {
    return {};
  }
  return { phError: true };
};

export const getNameParts = (fullName) => {
  // Deprecated, use shared/helpers/form.js
  let firstName, lastName;
  if (fullName) {
    const fullNameArr = fullName.split(' ');
    firstName = fullNameArr.length > 1 ? fullNameArr.slice(0, -1).join(' ') : fullNameArr[0];
    lastName = fullNameArr.length > 1 ? fullName.split(' ').pop() : '';
  }
  return {
    firstName,
    lastName,
  };
};

export const getFullName = (first, last) => {
  // Deprecated, use shared/helpers/form.js
  let fullName;
  if (first || last) {
    fullName = first + ' ' + last;
  }
  return fullName;
};

const _getFormName = (formId) => {
  if (formId === process.env.NEXT_PUBLIC_PROFESSIONAL_FORMID) {
    return 'professional';
  } else if (formId === process.env.NEXT_PUBLIC_ENTERPRISE_FORMID) {
    return 'enterprise';
  } else if (formId === process.env.NEXT_PUBLIC_NEWSLETTER_FORMID) {
    return 'newsletter';
  } else if (formId === process.env.NEXT_PUBLIC_TALENT_FORMID) {
    return 'talent';
  }
};

export const submitHubspotForm = async (formId, pageUrl, pageName, initData) => {
  const { fullName, ...restData } = initData;
  const { firstName, lastName } = getNameParts(fullName);
  const hutk = cookies.get('hubspotutk');
  const data = {
    ...restData,
    firstName,
    lastName,
    hutk,
    pageUrl,
    pageName,
    formGuid: formId,
    portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
  };

  try {
    const response = await request.post(`hubspot-forms/`, data);
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};
