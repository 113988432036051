import React from 'react';
import Carousel from 'nuka-carousel';
import { press } from '../data.js';
import { NextArrow, PrevArrow, Types } from './arrows.jsx';
import Styles from './PressSlider.module.scss';
import PropTypes from 'prop-types';
import PressItem from './PressItem';

const PressSlider = ({ type, light }) => {
  const arrowType = light ? Types.DARK : Types.LIGHT;

  const settings = {
    cellSpacing: 20,
    wrapAround: true,
    swiping: true,
    dragging: true,
    easing: 'easeQuadInOut',
    cellAlign: 'left',
    edgeEasing: 'easeQuadInOut',
    speed: 300,
    framePadding: '30px',
    frameOverflow: 'hidden',
    initialSlideHeight: 180,
    slidesToShow: 4,
    slideWidth: '300px',
    slideIndex: 3,
    renderCenterLeftControls: ({ previousSlide }) => (
      <PrevArrow onClick={previousSlide} type={arrowType} />
    ),
    renderCenterRightControls: ({ nextSlide }) => (
      <NextArrow onClick={nextSlide} type={arrowType} />
    ),
    renderBottomCenterControls: null,
  };

  return (
    <div className={Styles.container}>
      <Carousel {...settings} className={Styles.container}>
        {press.map((item, index) => {
          const firstClass = index === 0 ? 'first' : '';
          const lastClass = index === press.length - 1 ? 'last' : '';
          return (
            <PressItem
              key={index}
              className={`${firstClass} ${lastClass}`}
              item={item}
              image={item.imageName}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

PressSlider.propTypes = {
  type: PropTypes.string,
  light: PropTypes.bool,
};

export default PressSlider;
