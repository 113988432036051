import React from 'react';
import Styles from './Section.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Wave from '../Svg/backgrounds/wave.inline.svg';

export const ContainerType = {
  CIRCLE: Styles.containerCircle,
  LIGHTBLUE: Styles.containerLightblue,
  DARKBLUE: Styles.containerDarkblue,
  ENTERPRISE: Styles.containerEnterprise,
  ENTERPRISE_FULL: Styles.containerEnterpriseFull,
  PRIMARY_BLUE: Styles.containerPrimaryblue,
  LIGHTBLUE_GRADIENT: Styles.containerLightblueGradient,
  WHITE: Styles.containerPlainWhite,
};

const SectionContainer = ({ children, className, style, anchor, type, split }) => {
  return (
    <div
      className={classNames(Styles.container, split && Styles.splitContainer, type, className)}
      style={style}>
      <span className={Styles.anchor} id={anchor} />

      {children}
      {type === ContainerType.DARKBLUE && (
        <div className={Styles.waveContainer}>
          <Wave className={Styles.wave} />
        </div>
      )}
    </div>
  );
};

SectionContainer.Type = ContainerType;
SectionContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  anchor: PropTypes.string,
  type: PropTypes.string,
  split: PropTypes.bool,
};

export default SectionContainer;
