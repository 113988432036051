import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '../../Text/Text';
import Img from '../../Img';
import { Section, SectionContainer } from '../../Section';
import ButtonLink from '../../Button/ButtonLink';
import Styles from './CallToAction.module.scss';

const GenericCta = ({
  title,
  description,
  linkTo,
  buttonLabel,
  className,
  analyticsData,
  ...props
}) => {
  return (
    <SectionContainer
      type={SectionContainer.Type.DARKBLUE}
      style={{ minHeight: '300px' }}
      anchor='midcta'>
      <Section className={classNames(Section.Styles.innerContainerSection, className)}>
        <div className={Styles.wrapper}>
          <div className={Styles.imageContainer}>
            <Img
              src={`/images/resumePaul.jpg`}
              alt='image'
              className={Styles.imageItem}
              wrapperClassName={Styles.image}
            />
          </div>
          <div className={Styles.textContainer}>
            <Text size={Text.Size.SUBHEADLINE} variation={Text.Variation.WHITE}>
              {title}
            </Text>
            <Text element='p' variation={Text.Variation.WHITE}>
              {description}
            </Text>
            <ButtonLink
              type={ButtonLink.Type.WHITE}
              className={Styles.sectionCta}
              title=''
              href={{
                pathname: linkTo,
                query: { ...analyticsData },
              }}>
              {buttonLabel}
            </ButtonLink>
          </div>
        </div>
      </Section>
    </SectionContainer>
  );
};

GenericCta.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  description: PropTypes.string,
  linkTo: PropTypes.string,
  className: PropTypes.string,
};

export default GenericCta;
