import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '../Loader/Loader';
import Styles from './Button.module.scss';

export const ButtonType = {
  BLUE: Styles.blue,
  DARKBLUE: Styles.darkblue,
  WHITE: Styles.white,
  WHITE_BLUE_OUTLINE: Styles.whiteBlueOutline,
  BLUE_OUTLINE: Styles.blueOutline,
};

export const ButtonSize = {
  FULLWIDTH: Styles.fullwidth,
  FULLWIDTH_LARGE: Styles.fullwidthLarge,
  LARGE: Styles.large,
};

const Button = ({
  type,
  size,
  className,
  children,
  content,
  disabled,
  isLoading,
  ...restProps
}) => {
  const componentClasses = classNames(Styles.btn, type, size, className);
  return (
    <button type='button' className={componentClasses} disabled={disabled} {...restProps}>
      {isLoading && (
        <Loader
          style={{ marginRight: '10px' }}
          color={Loader.Color.WHITE}
          size={Loader.Size.SMALL}
        />
      )}

      {content || children}
    </button>
  );
};

Button.Type = ButtonType;
Button.Size = ButtonSize;
Button.Styles = Styles;

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
  className: PropTypes.string,
  restProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Button;
