import React from 'react';
import classNames from 'classnames';
import Img from '../Img';
import Link from '../Link';
import PropTypes from 'prop-types';
import Styles from './Story.module.scss';

const Story = ({ story, className, latest, ...props }) => {
  const srcSet =
    story.featuredImage?.node?.srcSet ||
    story.featuredImage?.node?.mediaDetails?.sizes
      ?.map((a) => `${a.sourceUrl} ${a.width}w`)
      .join(', ');
  const oldSizes = `(max-width: 300px) 100vw, 1000px`;
  return (
    <Link
      className={classNames(latest ? Styles.latest : Styles.item, Styles.story)}
      href={`/blog/${story.slug}`}
      title={story.title}>
      <Img
        title={story.title}
        alt={story.title}
        height={180}
        className={Styles.image}
        src={story.featuredImage?.node?.sourceUrl}
        sizes={story.featuredImage?.node?.sizes}
        srcSet={srcSet}
      />
      <div className={Styles.tags}>
        {story.tags.nodes.slice(0, 2).map((tag, j) => (
          <div key={j} className={Styles.tag}>
            {tag.name}
          </div>
        ))}
      </div>
      <div className={Styles.content}>
        <h3 className={Styles.title}>{story.title}</h3>
        <div
          className={Styles.text}
          dangerouslySetInnerHTML={{
            __html: story.excerpt || '',
          }}></div>
        <div className={Styles.more}>
          <span className={Styles.moreText}>{`Read More`}</span>
          <span className={Styles.moreArrow}>&rarr;</span>
        </div>
      </div>
    </Link>
  );
};

Story.propTypes = {
  story: PropTypes.object,
  className: PropTypes.string,
};

export default Story;
