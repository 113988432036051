import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Column.module.scss';

const List = React.memo(({ className, children, ...props }) => {
  return (
    <div className={classNames(Styles.list, className)} {...props}>
      {children}
    </div>
  );
});

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

List.defaultProps = {
  className: '',
};

export default List;
