import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import MainLayout from '../components/Layout/Layout';
import { getUsers, mergeCardUsersData, limitUsersData } from '../shared/helpers/users';
import { replaceJson } from '../shared/helpers/utils';
import { getStoriesBySlug } from '../shared/helpers/posts';
import MainHero from '../components/PageComponents/Hero/MainHero/MainHero';
const WhyWork = dynamic(() => import('../components/PageComponents/WhyWork/WhyWork'));
const TalentTypes = dynamic(() => import('../components/PageComponents/TalentTypes/TalentTypes'));
const GenericCta = dynamic(() => import('../components/PageComponents/CallToAction/GenericCta'));
const CallToAction = dynamic(() =>
  import('../components/PageComponents/CallToAction/CallToAction')
);
const Workflow = dynamic(() => import('../components/PageComponents/Workflow/Workflow'));
const PressSection = dynamic(() =>
  import('../components/PageComponents/PressSection/PressSection')
);
const PostSection = dynamic(() => import('../components/PageComponents/PostSection/PostSection'));

const Home = ({
  hero,
  head,
  footer,
  midCtaSection,
  customerNames,
  cardUsers,
  posts,
  postData,
  ...props
}) => {
  return (
    <MainLayout footerData={footer} pageHead={head}>
      <MainHero
        customerNames={customerNames}
        users={cardUsers}
        title={hero.title}
        description={hero.description}
        talentCtaLinkTo={`/talents/register`}
        talentCtaLabel={`Apply As a Talent`}
        brandCtaLinkTo={`/company/register`}
        brandCtaLabel={`Work With Talents`}
      />
      <TalentTypes />
      <WhyWork />
      <GenericCta
        image={`/images/resumePaul.jpg`}
        title={midCtaSection.title}
        description={midCtaSection.description}
        linkTo={midCtaSection.buttonLinkTo}
        buttonLabel={midCtaSection.buttonLabel}
      />
      <Workflow image={`/images/workingHome.png`} />
      <PressSection />
      <PostSection posts={posts} postData={postData} />
      <CallToAction />
    </MainLayout>
  );
};

Home.propTypes = {
  hero: PropTypes.object,
  head: PropTypes.object,
  footer: PropTypes.object,
  midCtaSection: PropTypes.object,
  customerNames: PropTypes.array,
  cardUsers: PropTypes.array,
  posts: PropTypes.array,
  postData: PropTypes.array,
};
export default Home;

export const getStaticProps = async (context) => {
  const data = replaceJson(require('../../data/pages/01_main_pages.json'));
  const pageData = data.pages.find((page) => page.slug === '/');

  const { users = [], ...restPageData } = pageData;
  const jsonUsers = [];
  const userUuids = users.filter(({ uuid }) => !!uuid).map(({ uuid }) => uuid);
  let apiUsers = [];
  if (userUuids?.length) {
    apiUsers = await getUsers(userUuids);
  }

  const posts = pageData.posts;
  const articles = posts.map(({ slug }) => slug);
  const postData = await getStoriesBySlug(articles);

  const cardUsers = limitUsersData(mergeCardUsersData(jsonUsers, users, apiUsers));

  return {
    props: { ...restPageData, cardUsers, postData },
  };
};
