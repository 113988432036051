/*
 * Top level app & layout, loaded ONCE at the start
 */
import React, { useEffect } from 'react';

// import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import 'intersection-observer';
import '../styles/global.scss';
//import 'react-datepicker/dist/react-datepicker.css';
//import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import '../lib/prism.css';

import * as gtm from '../lib/gtm';
// import * as gtag from '../lib/gtag';
// import * as fullstory from '../lib/fullstory';
import * as intercom from '../lib/intercom';
import * as hubspot from '../lib/hubspot';

const Helper = dynamic(() => import('../components/Layout/Helper'));
// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps, err }) => {
  // const [pageIsLoading, setLoading] = React.useState(false);
  useEffect(() => {
    intercom.boot();

    const startRouteChange = (url) => {
      // setLoading(true);
    };
    const endRouteChange = (url) => {
      gtm.pageView(url);
      intercom.sendEvent();
      // setLoading(false);
    };

    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      Router.events.on('routeChangeStart', startRouteChange);
      Router.events.on('routeChangeComplete', endRouteChange);
      Router.events.on('routeChangeError', endRouteChange);
    }

    return () => {
      Router.events.off('routeChangeStart', startRouteChange);
      Router.events.off('routeChangeComplete', endRouteChange);
      Router.events.off('routeChangeError', endRouteChange);
    };
  }, []);

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />

        {gtm.script()}
        {gtm.dataLayer()}
        {intercom.script()}
      </Head>
      <Component {...pageProps} err={err} />
      <Helper />
    </>
  );
};

export default App;
