import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Column.module.scss';

const Content = React.memo(({ className, children, ...props }) => {
  return (
    <p className={classNames(Styles.content, className)} {...props}>
      {children}
    </p>
  );
});

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Content.defaultProps = {
  className: '',
};

export default Content;
