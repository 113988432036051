import React from 'react';
import Styles from './Hero.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import WaveFive from '../../Svg/backgrounds/wavefive.inline.svg';
import WaveFour from '../../Svg/backgrounds/wavefour.inline.svg';

export const Parent = {
  MAIN: Styles.main,
  CLIENT: Styles.client,
  ENTERPRISE: Styles.enterprise,
  BRIDGE: Styles.bridge,
  VERTICAL: Styles.enterprise,
  TALENT: Styles.talent,
  CONTACT: Styles.contact,
  REFERRAL: Styles.referral,
  ABOUT: Styles.about,
  FAQ: Styles.faq,
  CAREERS: Styles.careers,
  ERROR: Styles.error,
  PROFILE: Styles.profile,
  SEO: Styles.seo,
  CREATOR: Styles.creator,
  LOGIN: Styles.login,
  REGISTER: Styles.register,
  ENGAGEMENT: Styles.engagement,
  COMPANY_REGISTER: Styles.companyRegister,
};

export const heroStyles = Styles;

export default function Hero(props) {
  return (
    <div className={props.parent} style={props.style}>
      {props.wave && (
        <div className={Styles.waveContainer}>
          {/*<div className={classNames(Styles.wave, Styles.waveStatic)} />*/}
          {/*<div className={classNames(Styles.wave, Styles.two, Styles.waveStatic)} />*/}
          {/*<div className={classNames(Styles.wave, Styles.three, Styles.waveStatic)} />*/}
          <div className={classNames(Styles.waveFive)}>
            {/*<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
              <path
                fill='currentColor'
                d='M0 32l120 26.7C240 85 480 139 720 160s480 11 600 5.3l120-5.3V0H0z'
              />
            </svg>*/}
            {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
              <path
                fill='currentColor'
                d='M0 32h60c60 0 180 0 300 26.7C480 85 600 139 720 181.3 840 224 960 256 1080 272s240 16 300 16h60V0H0z'
              />
            </svg> */}
            <WaveFive />
          </div>
          <div className={classNames(Styles.waveFour)}>
            {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
              <path
                fill='currentColor'
                d='M0 64l80-5.3c80-5.7 240-15.7 400 16C640 107 800 181 960 208c160 27 320 5 400-5.3l80-10.7V0H0z'
              />
            </svg> */}
            <WaveFour />
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}

Hero.Page = Parent;
Hero.Styles = Styles;

Hero.propTypes = {
  parent: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  wave: PropTypes.bool,
};
