import React from 'react';
// import { SLIDER_TYPE } from './Container';
import PropTypes from 'prop-types';
import Img from '../Img';

// Components
import SkillSet from '../SkillSet/SkillSet';
import Styles from './UserSlider.module.scss';

export default function Item({ item }) {
  let user = item;
  if (item.node) {
    user = item.node;
  }
  return (
    <a
      className={Styles.portfolioItem}
      href={`/resumes/${user.username}`}
      target={'_blank'}
      rel={'noopener noreferrer'}
      title={user.name}>
      <Img
        className={Styles.image}
        wrapperClassName={Styles.imageContainer}
        src={user.picture}
        height={`120px`}
        width={`120px`}
        alt={user.name}
      />
      <p className={Styles.name}>{user.name}</p>
      <p className={Styles.primarySkill}>{user.primarySkillTitle}</p>
      <SkillSet className={Styles.skills} skills={user?.skills?.slice(0, 3)} center />
    </a>
  );
}

Item.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
};
