import React from 'react';

export const convertPropsToHeadElements = (attributes) => {
  if (!attributes) return null;
  const attributeKeys = Object.keys(attributes);
  const helmetAttributes = [];

  for (let i = 0; i < attributeKeys.length; i++) {
    const Elem = attributeKeys[i];
    const data = attributes[attributeKeys[i]];

    if (typeof data === 'string' || data instanceof String) {
      helmetAttributes.push(<Elem key={attributeKeys[i]}>{data}</Elem>);
    } else if (Array.isArray(data)) {
      for (let j = 0; j < data.length; j++) {
        const arrDataObj = data[j];
        helmetAttributes.push(
          <Elem key={arrDataObj['name'] || arrDataObj['property']} {...arrDataObj} />
        );
      }
    }
  }

  return helmetAttributes;
};

export const cleanPageHead = (pageHead) => {
  const newPageHead = pageHead;
  if (!newPageHead) return null;
  if (newPageHead.meta === null) {
    newPageHead.meta = [];
  }
  if (newPageHead.link === null) {
    newPageHead.link = [];
  }
  return newPageHead;
};
export const getMetaContent = (head, name) => {
  if (!head || !head.meta) {
    return null;
  }
  if (Array.isArray(head.meta)) {
    const metaObj = head.meta.find((meta) => {
      return meta.name === name || meta.property === name;
    });
    if (metaObj) {
      return metaObj.content;
    }
  } else if (head.meta.name === name || head.meta.property === name) {
    return head.meta.content;
  }
  return null;
};

export const generateSchema = (
  { title, image, description, url },
  postSchema,
  schemaFaq,
  isPost = false
) => {
  const data = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: `${process.env.NEXT_PUBLIC_SITE_NAME}`,
    },
  ];

  if (isPost) {
    data.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': url,
              name: title,
              image,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: `${process.env.NEXT_PUBLIC_SITE_NAME}`,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': url,
        },
        author: {
          '@type': 'Person',
          name: postSchema?.author || 'Hire Digital Editorial',
        },
        publisher: {
          '@type': 'Organization',
          name: postSchema?.publisher || 'Hire Digital',
          logo: {
            '@type': 'ImageObject',
            url: 'http://cdn.hiredigital.com/email/hd-logo-small.png',
          },
        },
        datePublished: postSchema?.datePublished,
        dateModified: postSchema?.dateModified,
      },
      { ...schemaFaq }
    );
  } else {
    data.push(
      {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url,
        name: title,
        alternateName: `${process.env.NEXT_PUBLIC_SITE_NAME}`,
        image,
        description,
      },
      { ...schemaFaq }
    );
  }

  return JSON.stringify(data);
};

export const generateSocial = ({ title, image, description, url }, isPost = false) => {
  return {
    meta: [
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      image ? { property: 'twitter:image', content: image } : {},
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@thecontentco' },
      { name: 'twitter:creator', content: '@thecontentco' },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      image ? { property: 'og:image', content: image } : {},
      { property: 'og:url', content: url },
      { property: 'og:type', content: isPost ? 'article' : 'website' },
    ],
  };
};

export const generateProd = () => {
  return {
    meta: [{ property: 'fb:app_id', content: process.env.NEXT_PUBLIC_FB_APP_ID }],
  };
};

export const generateDev = () => {
  return {
    meta: [{ name: 'robots', content: 'noindex' }],
  };
};

export const generateFaqSchema = (data) => {
  if (!data) return;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };

  data?.map(({ answer, question }) => {
    schema.mainEntity.push({
      '@type': 'Question',
      name: removeExtraSpace(removeLineBreaks(question)),
      acceptedAnswer: {
        '@type': 'Answer',
        text: removeExtraSpace(removeLineBreaks(answer)),
      },
    });
  });

  return schema;
};

const removeLineBreaks = (t) => t?.replace(/(\r\n|\n|\r|\\)/gm, '');
const removeExtraSpace = (t) => t?.replace(/\s\s+/g, ' ');
