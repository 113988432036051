import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ListLink from '../ListLink/ListLink';
import Logo from '../Logo/Logo';
import FooterNav from './Nav';
import Facebook from '../Svg/Facebook';
import Linkedin from '../Svg/Linkedin';
import Twitter from '../Svg/Twitter';

import Styles from './Footer.module.scss';

const Footer = React.memo(({ darkFooter, data }) => {
  // const copyright = `Copyright © 2021. ${process.env.NEXT_PUBLIC_SITE_NAME}`;
  const listLinkType = darkFooter ? ListLink.Type.WHITE : ListLink.Type.DARKGRAY;
  return (
    <footer
      className={classNames({
        [Styles.darkblue]: darkFooter,
      })}
      data-test-id='footer'>
      <div className={Styles.sitemap}>
        <div className={classNames(Styles.container, Styles.containerTop)}>
          <div className={Styles.description}>
            <Logo
              className={Styles.logo}
              type={darkFooter ? Logo.Type.WHITE : Logo.Type.DARKGRAY}
            />
            <p className={Styles.descriptionText}>
              {`${process.env.NEXT_PUBLIC_SITE_NAME} connects businesses to a curated network of top talents in content, marketing and digital to fulfill immediate and long-term needs. Our algorithm screens tens of thousands of talents for their technical knowledge, domain expertise and soft skills, to match the most qualified talents to you.`}
            </p>
          </div>
          <div className={Styles.flexSpacer} />
          <FooterNav title='Solutions' className={Styles.footerNav}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/'>
              {`Home`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/clients'>
              {`Clients`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/enterprise'>
              {`Enterprise`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/talents'>
              {`Become a Talent`}
            </ListLink>
          </FooterNav>

          <FooterNav title='Resources' className={Styles.footerNav}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/blog'>
              {`Blog`}
            </ListLink>
            <ListLink
              type={listLinkType}
              className={Styles.navLink}
              to={
                process.env.NEXT_PUBLIC_SITE_ID === 1
                  ? `https://resources.content.co`
                  : `https://resources.hiredigital.com`
              }>
              {`Resources`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/faq'>
              {`FAQ`}
            </ListLink>

            <ListLink type={listLinkType} className={Styles.navLink} to='/terms'>
              {`Terms`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/privacy'>
              {`Privacy`}
            </ListLink>
          </FooterNav>

          <FooterNav title='Company' className={Styles.footerNav}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/about' external>
              {`About`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/contact'>
              {`Contact`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/careers'>
              {`Careers`}
            </ListLink>
          </FooterNav>
        </div>
      </div>

      <div className={classNames(Styles.contact, Styles.container)}>
        <div className={Styles.section}>
          <a
            className={classNames(Styles.contactLink)}
            href={data ? `mailto:${data.email}` : `mailto:${process.env.NEXT_PUBLIC_CONTACT_EMAIL}`}
            title='Enquiry'>
            {data ? data.email : process.env.NEXT_PUBLIC_CONTACT_EMAIL}
          </a>
        </div>
        <div className={Styles.section}>
          <a
            className={classNames(Styles.contactLink)}
            href={data ? `tel:${data.phone}` : 'tel:+1 (617) 528-0943'}
            title='phone'>
            {data ? data.phone : '+1 (617) 528-0943'}
          </a>
        </div>
        <div className={classNames(Styles.section, Styles.rightSection)}>
          <a
            className={Styles.linkIcon}
            href={`${process.env.NEXT_PUBLIC_FACEBOOK_URL}`}
            rel='noopener noreferrer'
            title={`${process.env.NEXT_PUBLIC_SITE_NAME} Facebook`}
            target='_blank'>
            <Facebook className={Styles.icon} />
          </a>
          <a
            className={Styles.linkIcon}
            href={`${process.env.NEXT_PUBLIC_TWITTER_URL}`}
            rel='noopener noreferrer'
            title={`${process.env.NEXT_PUBLIC_SITE_NAME} Twitter`}
            target='_blank'>
            <Twitter className={Styles.icon} />
          </a>
          <a
            className={Styles.linkIcon}
            href={`${process.env.NEXT_PUBLIC_LINKEDIN_URL}`}
            rel='noopener noreferrer'
            title={`${process.env.NEXT_PUBLIC_SITE_NAME} LinkedIn`}
            target='_blank'>
            <Linkedin className={Styles.icon} />
          </a>
        </div>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  darkFooter: PropTypes.bool,
  data: PropTypes.object,
};

export default Footer;
