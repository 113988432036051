import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Hero from '../Hero';
// import Customer from '../../../NewCustomer/Customer';
import HeroButton from '../HeroButton';

import NewUserSlider from '../../../UserSlider/NewUserSlider';

import Boot from '../../../Svg/backgrounds/boot.inline.svg';
import Styles from './Styles.module.scss';

const Customer = dynamic(() => import('../../../NewCustomer/Customer'));

const MainHero = ({
  users,
  order,
  title,
  description,
  talentCtaLinkTo,
  talentCtaLabel,
  brandCtaLinkTo,
  brandCtaLabel,
  customerNames,
  analyticsData,
  ...props
}) => {
  return (
    <Hero parent={Hero.Page.MAIN} wave>
      <div className={Styles.container}>
        <div className={Styles.heroMainTop}>
          <h1 className={Styles.heroTitle}>{title}</h1>
          <p className={Styles.heroMainDes}>{description}</p>
          <div className={classNames(Styles.cMainHeroCta, Styles.gridReverse)}>
            <HeroButton
              type={HeroButton.Type.DARKBLUE}
              className={Styles.btnCta}
              title=''
              href={{
                pathname: talentCtaLinkTo,
                query: { ...analyticsData },
              }}>
              {talentCtaLabel}
            </HeroButton>
            <HeroButton
              type={HeroButton.Type.WHITE}
              className={Styles.btnCta}
              title=''
              href={{
                pathname: brandCtaLinkTo,
                query: { ...analyticsData },
              }}>
              {brandCtaLabel}
            </HeroButton>
          </div>
        </div>
      </div>
      <div className={Styles.bottom}>
        <Customer />
      </div>
      {users && <NewUserSlider bottom users={users} order={order || users} />}

      <Boot className={Styles.boot} />
    </Hero>
  );
};

MainHero.propTypes = {
  users: PropTypes.array,
  order: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  talentCtaLinkTo: PropTypes.string,
  talentCtaLabel: PropTypes.string,
  brandCtaLinkTo: PropTypes.string,
  brandCtaLabel: PropTypes.string,
  customerNames: PropTypes.array,
};

export default MainHero;
