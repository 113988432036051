import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { PopupType } from '../Popup/Popup';

import { defaultHead } from './defaults.js';
import {
  getMetaContent,
  generateSchema,
  generateSocial,
  generateProd,
  generateDev,
  cleanPageHead,
  convertPropsToHeadElements,
  generateFaqSchema,
} from './utils.jsx';

// const Footer = dynamic(() => import('../Footer/Footer'));
const Popup = dynamic(() => import('../Popup/Popup'));
const LiveChat = dynamic(() => import('./LiveChat'));
const Announcement = dynamic(() => import('../Announcement/Announcement'));

const MainLayout = ({
  footerData,
  darkFooter,
  children,
  hasHero,
  pageHead: origPageHead,
  isPost,
  postSchema,
  variant,
  type,
  buttonLabel,
  desktopMatType,
  analyticsData,
  faqData,
}) => {
  const router = useRouter();
  const pageHead = cleanPageHead(origPageHead);
  var currentPath = router.asPath.split('?')[0];
  const pageUrl = process.env.NEXT_PUBLIC_SITE_URL + currentPath;
  const canonicalUrl = process.env.NEXT_PUBLIC_CANONICAL_URL + currentPath;

  // const data = useLayoutQuery();
  // Note that the only one cheat we have is to allow a single image meta but it does not exist
  const commonMeta = {
    title: isPost
      ? getMetaContent(pageHead, 'og:title')
      : (pageHead && pageHead.title) || defaultHead.title,
    image: getMetaContent(pageHead, 'image') || getMetaContent(defaultHead, 'image'),
    description:
      getMetaContent(pageHead, 'description') || getMetaContent(defaultHead, 'description'),
    url: pageUrl,
  };

  const socialHead = generateSocial(commonMeta, isPost);
  const envHead = process.env.NEXT_PUBLIC_ENV === 'prod' ? generateProd() : generateDev();
  const schemaFaq = generateFaqSchema(faqData);
  const schemaData = generateSchema(commonMeta, postSchema, schemaFaq, isPost);

  return (
    <>
      <Head>
        {convertPropsToHeadElements(defaultHead)}
        {convertPropsToHeadElements(pageHead)}
        {convertPropsToHeadElements(socialHead)}
        {convertPropsToHeadElements(envHead)}
        <link rel='canonical' href={canonicalUrl} />
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: schemaData }}></script>
      </Head>
      <Announcement />
      {/*type={hasHero ? Header.Type.HERO : Header.Type.PAGES}*/}
      <Header
        type={type}
        variant={variant}
        buttonLabel={buttonLabel}
        analyticsData={analyticsData}
      />
      <div className={classNames(Header.Styles.main, hasHero && Header.Styles.hasHero)}>
        {children}
      </div>
      <Footer darkFooter={darkFooter} data={footerData} analyticsData={analyticsData} />
      <LiveChat />
      <Popup location={router} type={desktopMatType} analyticsData={analyticsData} />
    </>
  );
};

MainLayout.Variants = Header.Variants;
MainLayout.Types = Header.Types;
MainLayout.desktopMatType = PopupType;

// headerMenuType
MainLayout.propTypes = {
  footerData: PropTypes.object,
  children: PropTypes.node,
  hasHero: PropTypes.bool,
  darkFooter: PropTypes.bool,
  pageHead: PropTypes.object,
  isPost: PropTypes.bool,
  variant: PropTypes.number,
  type: PropTypes.string,
  buttonLabel: PropTypes.string,
  desktopMatType: PropTypes.string,
  analyticsData: PropTypes.object,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
};

MainLayout.defaultProps = {
  hasHero: true,
  isPost: false,
  variant: Header.Variants.DEFAULT,
  type: Header.Types.DARK,
  desktopMatType: PopupType.BOTTOM_PAGE_MODAL,
};

export default MainLayout;
