import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import List from './List';
import Card from './Card';
import Headline from './Headline';
import Content from './Content';
import Action from './Action';
import Image from './Image';
// import Styles from './Styles.module.scss';

const Column = React.memo(({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
});

Column.Content = Content;
Column.Headline = Headline;
Column.Image = Image;

Column.Item = Item;
Column.Card = Card;
Column.Action = Action;
Column.List = List;

Column.propTypes = {
  children: PropTypes.node,
};
export default Column;
