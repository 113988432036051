import React from 'react';
import Img from '../../Img';
import { Section, SectionContainer } from '../../Section';
import WorkflowStep from './Step';
import Grid from '../../Grid/Grid';
import PropTypes from 'prop-types';

export default function Workflow() {
  return (
    <SectionContainer type={SectionContainer.Type.LIGHTBLUE_GRADIENT} anchor='howitworks'>
      <Section
        className={Section.Styles.ruler}
        title={`How ${process.env.NEXT_PUBLIC_SITE_NAME} Works`}>
        <Grid columns={2} className={Grid.Styles.containerCenter}>
          <Grid.Item>
            <Img
              style={{ maxHeight: '550px', maxWidth: '100%' }}
              title={`Get matched to the best talents on ${process.env.NEXT_PUBLIC_SITE_NAME}`}
              alt={`Get matched to the best talents on ${process.env.NEXT_PUBLIC_SITE_NAME}`}
              src={`/images/workingHome.png`}
            />
          </Grid.Item>
          <Grid.Item>
            <WorkflowStep
              order='1'
              title='Speak to us'
              desc='Tell our marketing and growth experts (not recruiters) your business goals and project needs.'
            />
            <WorkflowStep
              order='2'
              title='Get introduced to top talents'
              desc='We can find you the talent you need in as fast as 24 hours.'
            />
            <WorkflowStep
              order='3'
              title='Start work'
              desc='Work with your hand-picked talent right away. We provide trials and guarantees to ensure fit.'
            />
          </Grid.Item>
        </Grid>
      </Section>
    </SectionContainer>
  );
}

Workflow.propTypes = {
  image: PropTypes.string,
};
