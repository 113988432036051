import React from 'react';
// import { graphql } from 'gatsby';
import Story from './Story';
import Styles from './List.module.scss';
import ButtonLink from '../Button/ButtonLink';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class StoryList extends React.Component {
  static propTypes = {
    list: PropTypes.array,
    start: PropTypes.number,
    end: PropTypes.number,
    hideMore: PropTypes.bool,
    order: PropTypes.array,
  };

  static defaultProps = {
    order: null,
    start: 0,
    end: 3,
    list: [],
    hideMore: false,
  };

  render() {
    const { list, start, end, hideMore, order } = this.props;
    if (order) {
      list.sort((a, b) => order.indexOf(a.slug) - order.indexOf(b.slug));
    }
    return (
      <>
        <div className={classNames(Styles.list, list.length > 1 ? '' : Styles.listSingle)}>
          {list.slice(start, end).map((story, index) => {
            const post = story?.node || story;
            return (
              <Story
                className={classNames(Styles.item, list.length > 1 ? '' : Styles.itemSingle)}
                story={post}
                key={index}
              />
            );
          })}
        </div>
        <div className={Styles.more}>
          {!hideMore && (
            <ButtonLink
              type={ButtonLink.Type.BLUE_OUTLINE}
              size={ButtonLink.Size.LARGE}
              title=''
              href='/blog'>
              {`See More Stories`}
            </ButtonLink>
          )}
        </div>
      </>
    );
  }
}

export default StoryList;
