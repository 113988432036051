import React from 'react';

import ButtonLink from '../../Button/ButtonLink';
import Img from '../../Img';
import Column from '../../Column/Column';
import { Section, SectionContainer } from '../../Section';

const TalentTypes = () => {
  return (
    <SectionContainer type={SectionContainer.Type.CIRCLE} anchor='talents'>
      <Section
        className={Section.Styles.ruler}
        title='Gain access to a network of quality talents to drive results'
        subtitle='For your marketing, growth, content creation, editorial, communications and digital needs.'>
        <Column.List>
          <Column.Card>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                title='Digial Marketing Experts'
                alt='Digial Marketing Experts'
                src={`/images/talenttypes/specialists.svg`}
              />
            </Column.Image>
            <Column.Headline>{'Digital Marketing Experts'}</Column.Headline>
            <Column.Content>
              {
                'Social Media Marketers, SEO Experts, Digital PR Experts, PPC Experts, Content Strategists, Conversion Optimization Experts, Researchers and more.'
              }
            </Column.Content>
            <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/marketing'>
                {'Work With Digital Marketers'}
              </ButtonLink>
            </Column.Action>
          </Column.Card>
          <Column.Card>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img title='designers' alt='designers' src={`/images/talenttypes/designers.svg`} />
            </Column.Image>
            <Column.Headline>{'Designers'}</Column.Headline>
            <Column.Content>
              {
                'UI Designers, UX Researchers, Graphic Designers, Illustrators, Visual Designer, Interactive Designers, Art Directors and more.'
              }
            </Column.Content>
            <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/designers'>
                {'Work With Designers'}
              </ButtonLink>
            </Column.Action>
          </Column.Card>
          <Column.Card>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img title='writers' alt='writers' src={`/images/talenttypes/writers.svg`} />
            </Column.Image>
            <Column.Headline>{'Writers'}</Column.Headline>
            <Column.Content>
              {
                'Website, Social Media, Email Newsletter, White Paper and Presentation Copywriters. Case Study, Thought Leadership Writers and more.'
              }
            </Column.Content>
            <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/writers'>
                {'Work With Writers'}
              </ButtonLink>
            </Column.Action>
          </Column.Card>
          <Column.Card>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img title='writers' alt='writers' src={`/images/talenttypes/animators.svg`} />
            </Column.Image>
            <Column.Headline>{'Multimedia Talents'}</Column.Headline>
            <Column.Content>
              {
                'Motion Graphics Experts, 2D Animators, 3D Animators and Modellers, Video Editors, Photographers, Directors, Producers and more.'
              }
            </Column.Content>
            <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/multimedia'>
                {'Work With Multimedia Talents'}
              </ButtonLink>
            </Column.Action>
          </Column.Card>
          <Column.Card>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img title='developers' alt='developers' src={`/images/talenttypes/developers.svg`} />
            </Column.Image>
            <Column.Headline>{'Developers'}</Column.Headline>
            <Column.Content>
              {
                'Front-end Developers, Tagging Developers, Creative Technologists, Back-end Developers, Data Analysts and more.'
              }
            </Column.Content>
            <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/developers'>
                {'Work With Developers'}
              </ButtonLink>
            </Column.Action>
          </Column.Card>
          <Column.Card>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img title='teams' alt='teams' src={`/images/talenttypes/managers.svg`} />
            </Column.Image>
            <Column.Headline>{'Multi-Talent, Managed Teams'}</Column.Headline>
            <Column.Content>
              {`Project Managers, Digital Transformation Experts and more. Need to assemble a team? Get in touch with a ${process.env.NEXT_PUBLIC_SITE_NAME} Teams specialist.`}
            </Column.Content>
            <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.BLUE_OUTLINE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/enterprise'>
                {'Learn More about Teams'}
              </ButtonLink>
            </Column.Action>
          </Column.Card>
        </Column.List>
      </Section>
    </SectionContainer>
  );
};
export default TalentTypes;
