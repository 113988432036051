import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Column.module.scss';

const ImageType = {
  DEFAULT: Styles.image,
  ICON: Styles.icon,
  SMALLER_ICON: Styles.smallerIcon,
};

const Image = React.memo(({ className, children, type, ...props }) => {
  return (
    <div className={classNames(type, className)} {...props}>
      {children}
    </div>
  );
});

Image.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  type: ImageType.DEFAULT,
};

Image.Type = ImageType;

export default Image;
