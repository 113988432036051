export class Enum {
  constructor(enums) {
    this.enums = enums;
    this.values = Object.values(enums);
    for (const prop in enums) {
      if (enums.hasOwnProperty(prop)) {
        this[prop] = enums[prop];
      }
    }
  }

  // Copy over enum value for other usage, i.e. Enum.UserGender.MALE
  getId = (enumValue) => {
    const ret = null;
    if (enumValue) {
      return enumValue.id;
    }
    return ret;
  };

  getEnum = (id) => {
    return this.values.find((e) => {
      return e.id === id;
    });
  };

  getEnumByKey = (value, valueKey) => {
    return this.values.find((e) => {
      return e[valueKey || 'id'] === value;
    });
  };

  getLabel = (id) => {
    const en = this.values.find((e) => {
      return e.id === id;
    });
    if (en) {
      return en.label;
    }
    return null;
  };

  getEnumFromValue = (value, valueName) => {
    return this.values.find((e) => {
      return e[valueName] === value;
    });
  };

  getIds = (enumValues) => {
    const ret = [];
    if (enumValues) {
      enumValues.forEach((val, i) => {
        const enumId = this.getId(val);
        if (enumId) {
          ret.push(enumId);
        }
      });
    }
    return ret;
  };

  getEnums = (ids) => {
    const ret = [];
    if (ids) {
      ids.forEach((enumId, i) => {
        const enumVal = this.getEnum(enumId);
        if (enumVal) {
          ret.push(enumVal);
        }
      });
    }
    return ret;
  };
}

// Note: We use Currency instead of PaymentCurrency because this is what we have for exchange rates for our MAS API

export const Currency = new Enum({
  sgd: { id: 'SG', code: 'SGD', label: 'Singapore Dollar', icon: 'S$' },
  eur_sgd: { id: 'EU', code: 'EUR', label: 'Euro', icon: '€' },
  gbp_sgd: { id: 'GB', code: 'GBP', label: 'Pound Sterling', icon: '£' },
  usd_sgd: { id: 'US', code: 'USD', label: 'US Dollar', icon: '$' },
  aud_sgd: { id: 'AU', code: 'AUD', label: 'Australian Dollar', icon: 'AU$' },
  cad_sgd: { id: 'CA', code: 'CAD', label: 'Canadian Dollar', icon: 'C$' },
  cny_sgd_100: { id: 'CN', code: 'CNY', label: 'Chinese Renminbi', icon: '¥' },
  hkd_sgd_100: {
    id: 'HK',
    code: 'HKD',
    label: 'Hong Kong Dollar',
    icon: 'HK$',
  },
  inr_sgd_100: { id: 'IN', code: 'INR', label: 'Indian Rupee', icon: '₹' },
  idr_sgd_100: {
    id: 'ID',
    code: 'IDR',
    label: 'Indonesian Rupiah',
    icon: 'Rp',
  },
  jpy_sgd_100: { id: 'JP', code: 'JPY', label: 'Japanese Yen', icon: '¥' },
  krw_sgd_100: { id: 'KR', code: 'KRW', label: 'Korean Won', icon: '₩' },
  myr_sgd_100: {
    id: 'MY',
    code: 'MYR',
    label: 'Malaysian Ringgit',
    icon: 'RM',
  },
  twd_sgd_100: {
    id: 'TW',
    code: 'TWD',
    label: 'New Taiwan Dollar',
    icon: 'NT$',
  },
  nzd_sgd: { id: 'NZ', code: 'NZD', label: 'New Zealand Dollar', icon: 'NZ$' },
  php_sgd_100: { id: 'PH', code: 'PHP', label: 'Philippine Peso', icon: '₱' },
  qar_sgd_100: { id: 'QA', code: 'QAR', label: 'Qatar Riyal', icon: '﷼' },
  sar_sgd_100: {
    id: 'SA',
    code: 'SAR',
    label: 'Saudi Arabia Riyal',
    icon: '﷼',
  },
  chf_sgd: { id: 'CH', code: 'CHF', label: 'Swiss Franc', icon: 'CHF' },
  thb_sgd_100: { id: 'TH', code: 'THB', label: 'Thai Baht', icon: '฿' },
  aed_sgd_100: { id: 'AE', code: 'AED', label: 'UAE Dirham', icon: 'د.إ;' },
  vnd_sgd_100: { id: 'VN', code: 'VND', label: 'Vietnamese Dong', icon: '₫' },
});

export const TimeRate = new Enum({
  HOUR: { id: 1, label: 'per hour' },
  DAY: { id: 2, label: 'per day' },
  WEEK: { id: 3, label: 'per week' },
  MONTH: { id: 4, label: 'per month' },
  QUARTER: { id: 5, label: 'per quarter' },
  YEAR: { id: 6, label: 'per year' },
});

export const Site = new Enum({
  CONTENTCO: { id: 1, label: 'Content.co' },
  HIREDIGITAL: { id: 2, label: 'Hire Digital' },
});

export const ServiceRateType = new Enum({
  HOUR: { id: 1, label: 'per hour' },
  DAY: { id: 2, label: 'per day' },
  WEEK: { id: 3, label: 'per week' },
  MONTH: { id: 4, label: 'per month' },
  YEAR: { id: 6, label: 'per year' },
});

export const BriefRegistrationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
});

export const Role = new Enum({
  CLIENT: { id: 1, label: 'Client' },
  TALENT: { id: 2, label: 'Talent' },
  TALENT_REVIEW: { id: 3, label: 'Talent Review Admin' },
  TALENT_REVIEW_LIMITED: { id: 9, label: 'Talent Review Limited' },

  TALENT_SEARCH_ADMIN: { id: 7, label: 'Talent Search Admin' },
  TALENT_SEARCH_BRAND_RATES: {
    id: 10,
    label: 'Talent Search Limited (Brand Only)',
  },
  TALENT_SEARCH_TALENT_RATES: {
    id: 13,
    label: 'Talent Search Limited (Talent Only)',
  },

  BRIEF_SALES: { id: 6, label: 'Brief Sales Admin' },
  BRIEF_SALES_LIMITED: { id: 11, label: 'Brief Sales Limited' },
  BRIEF_PLACEMENT: { id: 8, label: 'Brief Placement Admin' },
  BRIEF_PLACEMENT_LIMITED: { id: 12, label: 'Brief Placement Limited' },
  BRIEF_ADMIN: { id: 4, label: 'Brief Full Admin' },

  CLIENT_ADMIN: { id: 14, label: 'Client Admin' },
  CLIENT_ADMIN_LIMITED: { id: 15, label: 'Client Limited' },
  ADMIN: { id: 5, label: 'Full Admin' },
});

export const CallStatus = new Enum({
  SCHEDULED: { id: 1, label: 'Scheduled' },
  CANCELLED: { id: 2, label: 'Cancelled' },
  RESCHEDULED: { id: 3, label: 'Rescheduled' },
  DRAFT: { id: 4, label: 'Draft' },
});

export const PreferredPlatform = new Enum({
  PHONE: { id: 1, label: 'Phone' },
  ZOOM: { id: 2, label: 'Zoom' },
  SKYPE: { id: 3, label: 'Skype' },
  WEB_CALL: { id: 4, label: 'Web call' },
});

export const OrganizationType = new Enum({
  ENTERPRISE: { id: 1, label: 'Enterprise' },
  PROFESSIONAL: { id: 2, label: 'Professional' },
});

export const EngagementStatus = new Enum({
  // DRAFT: { id: 1, label: 'Draft' },
  MATCH: { id: 2, label: 'Match' },
  TRIAL: { id: 3, label: 'Trial' },
  ACTIVE: { id: 4, label: 'Active' },
  COMPLETED: { id: 5, label: 'Completed' },
  CANCELLED: { id: 6, label: 'Cancelled' },
});

export const ReviewStatus = new Enum({
  NEEDS_REVIEW: { id: 1, label: 'To Review' },
  ACCEPTED: { id: 2, label: 'Approved' },
  REJECTED: { id: 3, label: 'Rejected' },
});

export const PortfolioType = {
  USER: 0,
  ORGANIZATION: 1,
  TEAM: 2,
  RESUME: 3,
  PORTFOLIO: 4,
  PUBLIC_RESUME: 5,
  PUBLIC_PORTFOLIO: 6,
  TALENT: 7,
  TEAM_SLUG: 8,
};

export const PortfolioItemType = new Enum({
  URL: { id: 1, label: 'URL', name: 'Link' },
  UPLOAD: { id: 2, label: 'Upload', name: 'Upload' },
  CASE_STUDY: { id: 3, label: 'Case Study', name: 'Case Study' },
});
