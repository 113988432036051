export const defaultHead = {
  title: `${process.env.NEXT_PUBLIC_SITE_NAME}`,
  meta: [
    {
      name: 'image',
      content:
        process.env.NEXT_PUBLIC_SITE_ID == 1
          ? 'http://cdn.content.co/marketing/open-graph/og.png'
          : 'http://cdn.hiredigital.com/open-graph/hd/image_opt.jpg',
    },
    {
      name: 'description',
      content: `Scale your content and digital marketing with ${process.env.NEXT_PUBLIC_SITE_NAME}'s exclusive talent network. Rigorously screened for your most important projects.`,
    },
  ],
  link: [
    // {
    //   rel: 'icon',
    //   type: 'image/ico',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/favicon.ico'
    //       : '/favicon/hd/favicon.ico',
    // },
    // {
    //   rel: 'icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/favicon-16x16.png'
    //       : '/favicon/hd/favicon-16x16.png',
    //   sizes: '16x16',
    // },
    // {
    //   rel: 'shortcut icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/favicon-32x32.png'
    //       : '/favicon/hd/favicon-32x32.png',
    //   sizes: '32x32',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-57x57.png'
    //       : '/favicon/hd/apple-touch-icon-57x57.png',
    //   sizes: '57x57',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-60x60.png'
    //       : '/favicon/hd/apple-touch-icon-60x60.png',
    //   sizes: '60x60',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-72x72.png'
    //       : '/favicon/hd/apple-touch-icon-72x72.png',
    //   sizes: '72x72',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-76x76.png'
    //       : '/favicon/hd/apple-touch-icon-76x76.png',
    //   sizes: '76x76',
    // },
    // {
    //   rel: 'icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/favicon-96x96.png'
    //       : '/favicon/hd/favicon-96x96.png',
    //   sizes: '96x96',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-114x114.png'
    //       : '/favicon/hd/apple-touch-icon-114x114.png',
    //   sizes: '114x114',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-120x120.png'
    //       : '/favicon/hd/apple-touch-icon-120x120.png',
    //   sizes: '120x120',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-144x144.png'
    //       : '/favicon/hd/apple-touch-icon-144x144.png',
    //   sizes: '144x144',
    // },
    // {
    //   rel: 'apple-touch-icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/apple-touch-icon-152x152.png'
    //       : '/favicon/hd/apple-touch-icon-152x152.png',
    //   sizes: '152x152',
    // },
    // {
    //   rel: 'icon',
    //   type: 'image/png',
    //   href:
    //     process.env.NEXT_PUBLIC_SITE_ID == 1
    //       ? '/favicon/cc/favicon-196x196.png'
    //       : '/favicon/hd/favicon-196x196.png',
    //   sizes: '196x196',
    // },
  ],
};
