import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from './Container';

const NewUserSlider = ({ users, bottom, order }) => {
  const [isMobile, setIsMobile] = useState(false);
  // useState(() => setSliderType());
  if (order) {
    //users.sort((a, b) => order.indexOf(a.userId) - order.indexOf(b.userId));
    users.sort((a, b) => a.orderNo - b.orderNo);
  }

  /**
   * Define if isMobile is mobile
   */
  function setSliderType() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 599) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setSliderType);
    setSliderType();
    return function removeListener() {
      window.removeEventListener('resize', setSliderType);
    };
  }, []);
  return <Container items={users} isMobile={isMobile} bottom={bottom} />;
};

NewUserSlider.propTypes = {
  users: PropTypes.array,
  bottom: PropTypes.bool,
  order: PropTypes.array,
};

NewUserSlider.defaultProps = {
  order: null,
};

export default NewUserSlider;
