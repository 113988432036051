import React from 'react';
import Styles from './Text.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({ element: Elem, children, size, variation, className, ...restProps }) => {
  return (
    <Elem className={classNames(size, variation, className)} {...restProps}>
      {children}
    </Elem>
  );
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  variation: PropTypes.string,
  element: PropTypes.node,
};

Text.defaultProps = {
  element: 'p',
  size: Styles.body,
  variation: Styles.standard,
};

Text.Variation = {
  NORMAL: Styles.standard,
  WHITE: Styles.white,
  PARAGRAPH: Styles.paragraph,
  CENTER: Styles.center,
  WHITE_CENTER: Styles.whiteCenter,
  DARKBLUE_CENTER: Styles.darkblueCenter,
};

Text.Size = {
  HEADLINE: Styles.headline,
  SUBHEADLINE: Styles.subheadline,
  SUBHEADLINE_LARGE: Styles.subheadlineLarge,
  INTRO: Styles.intro,
  HEADING_TWO: Styles.headingTwo,
  BODY: Styles.body,
  SECTION_BODY: Styles.sectionBody,
  DISPLAY: Styles.display,
  HUGE: Styles.huge,
};

export default Text;
