import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '../../Text/Text';
import HeroButton from '../Hero/HeroButton';
import Grid from '../../Grid/Grid';
import Styles from './CallToAction.module.scss';

const CallToAction = ({ analyticsData, talent, className, ...props }) => {
  const talentType = talent || 'talents';
  return (
    <div className={classNames(Styles.container, className)} id='cta-box'>
      <div className={classNames(Styles.box)}>
        <Grid columns={2} className={Grid.Styles.containerCenter}>
          <Grid.Item>
            <Text element='p' size={Text.Size.SUBHEADLINE} variation={Text.Variation.WHITE}>
              {`Hire the best ${talentType} today`}
            </Text>
            <Text element='p' variation={Text.Variation.WHITE}>
              {`We provide you with the most qualified
                  ${talentType} to meet your needs. Get in touch to learn more.`}
            </Text>
          </Grid.Item>
          <Grid.Item>
            <div className={classNames(Grid.Styles.reverse, Styles.buttons)}>
              <HeroButton
                type={HeroButton.Type.DARKBLUE}
                className={Styles.btnApply}
                title='Apply As a Talent'
                href={{
                  pathname: '/talents/register',
                  query: { ...analyticsData },
                }}>
                {`Apply As a Talent`}
              </HeroButton>
              <HeroButton
                type={HeroButton.Type.WHITE}
                className={Styles.btnApply}
                title='Work with Talents'
                href={{
                  pathname: '/company/register',
                  query: { ...analyticsData },
                }}>
                {`Work With Talents`}
              </HeroButton>
            </div>
          </Grid.Item>
        </Grid>
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  talent: PropTypes.string,
  className: PropTypes.string,
};

export default CallToAction;
