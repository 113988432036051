import React from 'react';
import { stripIndent } from 'common-tags';

export const script = () => {
  return (
    <script
      key='hubspot-script'
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            (function(l, p, d, s, x) {
                s=d.createElement(l);s.type='text/javascript';s.id='hs-script-loader';
                s.async=p;s.defer=p;s.src='https://js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID}.js'
                x=d.getElementsByTagName(l)[0];x.parentNode.insertBefore(s,x);
            })('script', true, document) 
            `,
      }}
    />
  );
};
