import React from 'react';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import Intercom from 'react-live-chat-loader/dist/components/Intercom';

const LiveChat = ({ darkFooter, data }) => {
  return (
    <LiveChatLoaderProvider
      provider='intercom'
      providerKey={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}>
      <Intercom color='#081d57' />
    </LiveChatLoaderProvider>
  );
};

export default LiveChat;
