import React from 'react';
import classNames from 'classnames';
import Styles from './Customer.module.scss';
import PropTypes from 'prop-types';
import Img from '../Img';

import Axa from './img/axa.svg';
import Expedia from './img/expedia.svg';
import Marriott from './img/marriott.svg';
import Shopify from './img/shopify.svg';
import Philips from './img/philips.svg';
import Samsung from './img/samsung.svg';
import Unilever from './img/unilever.svg';
import Roche from './img/roche.svg';
import MMM from './img/3m.svg';

const customers = [
  {
    height: 75,
    width: 70,
    svg: Axa,
    name: 'Axa',
    className: Styles.axa,
  },
  // {
  //   height: 75,
  //   width: 135,
  //   svg: Expedia,
  //   name: 'Expedia',
  //   className: Styles.expedia,
  // },

  {
    height: 75,
    width: 100,
    svg: Roche,
    name: 'Roche',
    className: Styles.roche,
  },

  // {
  //   height: 75,
  //   width: 156,
  //   svg: Samsung,
  //   name: 'Samsung',
  //   className: Styles.samsung,
  // },

  {
    height: 75,
    width: 165,
    svg: Shopify,
    name: 'Shopify',
    className: Styles.shopify,
  },

  // {
  //   height: 75,
  //   width: 85,
  //   svg: Marriott,
  //   name: 'Marriott',
  //   className: Styles.marriott,
  // },
  {
    height: 75,
    width: 70,
    svg: MMM,
    name: '3M',
    className: Styles.mmm,
  },

  {
    height: 75,
    width: 135,
    svg: Philips,
    name: 'Philips',
    className: Styles.philips,
  },
  {
    height: 75,
    width: 67,
    svg: Unilever,
    name: 'Unilever',
    className: Styles.unilever,
  },
];

const Customer = () => {
  return (
    <div className={Styles.list}>
      {customers.map((customer, index) => {
        return (
          <Img
            key={index}
            wrapperClassName={classNames(Styles.imageContainer, customer.className)}
            height={customer.height}
            width={customer.width}
            alt={customer.name}
            className={classNames(Styles.image, customer.className)}
            src={customer.svg}
          />
        );
      })}
    </div>
  );
};

Customer.propTypes = {};
Customer.defaultProps = {};
export default Customer;
