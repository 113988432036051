export const press = [
  {
    content: 'Inspiring stories from business owners and Xero partners',
    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/xero.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/xero@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/xero@3x.png',
    },
    imageName: 'xero',
    pressName: 'XERO',
    link: 'https://www.xero.com/blog/2020/07/behind-small-business-inspiring-stories/',
  },
  {
    content: 'Prestige 40 Under 40',
    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/prestige.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/prestige@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/prestige@3x.png',
    },
    imageName: 'prestige',
    pressName: 'PRESTIGE',
    link:
      'https://www.prestigeonline.com/sg/profiles/prestige-40-under-40-2020-chan-yiwen/?listid=210571&peoplein=39',
  },
  {
    content: 'Create Branded Content Your Audiences Value',
    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/forbes.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/forbes@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/forbes@3x.png',
    },
    imageName: 'forbes',
    pressName: 'FORBES',
    link:
      'https://www.forbes.com/sites/joeescobedo/2017/02/08/this-millennial-content-marketing-boss-has-5-great-tips-to-create-excellent-branded-content',
  },
  {
    content: 'A Martech Revolution',
    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/unbound.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/unbound@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/unbound@3x.png',
    },
    imageName: 'unbound',
    pressName: 'INNOVFEST UNBOUND',
    link: 'https://www.youtube.com/watch?v=FAvT5kAACWw',
  },
  {
    content: 'Tech Businesses To Watch',
    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/straitstimes.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/straitstimes@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/straitstimes@3x.png',
    },
    imageName: 'straitstimes',
    pressName: 'THE STRAITS TIMES',
    link: 'http://www.straitstimes.com/business/spore-start-ups-to-watch-in-2016',
  },
  {
    content: 'The Only Way To Win In Creating Content',

    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/marketing.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/marketing@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/marketing@3x.png',
    },
    imageName: 'marketing',
    pressName: 'MARKETING MAGAZINE',
    link: 'http://www.marketing-interactive.com/do-you-actually-know-how-to-speak-to-a-centennial/',
  },
  {
    content: 'Making Content Marketing Work in Asia',

    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/mumbrella.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/mumbrella@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/mumbrella@3x.png',
    },
    imageName: 'mumbrella',
    pressName: 'MUMBRELLA',
    link:
      'http://www.mumbrella.asia/2016/05/qa-with-bolt-media-co-founder-yi-wen-chan-brands-need-locally-based-creators-to-make-content-marketing-work-in-asia/',
  },
  {
    content: 'Leading Millennials: Yiwen Chan, Hire Digital',
    image: {
      sizex: 'https://cdn.hiredigital.com/marketing/press/splice.png',
      size2x: 'https://cdn.hiredigital.com/marketing/press/splice@2x.png',
      size3x: 'https://cdn.hiredigital.com/marketing/press/splice@3x.png',
    },
    imageName: 'splice',
    pressName: 'SPLICE MEDIA',

    link: 'https://www.splicemedia.com/leading-millennials-yiwen-chan-bolt-media/',
  },
];
