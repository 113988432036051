import React from 'react';
import { Section, SectionContainer } from '../../Section';
import StoryList from '../../Story/List';

const PostSection = ({ postData, posts, title }) => {
  // Have to get post data here

  return (
    <SectionContainer type={SectionContainer.Type.LIGHTBLUE}>
      <Section title={title || `Read ${process.env.NEXT_PUBLIC_SITE_NAME} Stories`}>
        <StoryList list={postData} order={posts} />
      </Section>
    </SectionContainer>
  );
};
export default PostSection;
