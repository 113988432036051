import React from 'react';
import { stripIndent } from 'common-tags';
import { paramStringFromObject } from './util';

const sharedData = { gaPropertyId: `${process.env.NEXT_PUBLIC_GA_ID}` };
const sharedParams = {};

const paramsString = () => {
  if (!sharedParams || Object.keys(sharedParams).length === 0) return '';
  return paramStringFromObject(sharedParams);
};

export const pageView = (url) => {
  const pageEvent = {
    event: 'pageview',
    page: url,
  };
  //@ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return;
};

export const sendEvent = (eventObj) => {
  window && window.dataLayer && window.dataLayer.push(eventObj);
  return;
};

export const dataLayer = () => {
  const data = Object.keys(sharedData).length > 0 ? JSON.stringify(sharedData) : '';
  return (
    <script
      key='gtm-datalayer'
      defer
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            window.dataLayer = window.dataLayer || [${data}];
          `,
      }}
    />
  );
};

export const script = () => {
  return (
    <script
      key='gtm-script'
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
      }}
    />
  );
};

export const noscript = () => {
  return (
    <noscript
      key='gtm-noscript'
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=${
                process.env.NEXT_PUBLIC_GTM_ID
              }${paramsString()}"
              height="0"
              width="0"
              style="display: none; visibility: hidden"
            ></iframe>`,
      }}
    />
  );
};
