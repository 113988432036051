import React from 'react';
import PropTypes from 'prop-types';

import Img from '../../Img';
import Styles from './PressSlider.module.scss';

const PressItem = ({ item, image }) => {
  return (
    <a
      href={item.link}
      className={Styles.link}
      target='_blank'
      rel='noopener noreferrer'
      title={item.pressName}>
      <div className={Styles.item}>
        <div className={Styles.header}>{item.content}</div>
        <div className={Styles.company}>
          <Img className={Styles.image} alt={item.pressName} src={`/images/press/${image}.png`} />
          <p className={Styles.companyName}>{item.pressName}</p>
        </div>
      </div>
    </a>
  );
};

PressItem.propTypes = {
  item: PropTypes.object,
  image: PropTypes.string,
};

export default PressItem;
