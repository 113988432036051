import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Styles from './Logo.module.scss';
import classNames from 'classnames';
import HireDigital from '../Svg/logo/hdlogo.inline.svg';
// import ContentCo from '../Svg/logo/cclogo.inline.svg';

const Logo = memo(({ className, type }) => {
  return <HireDigital className={classNames(Styles.hdLogo, className, type)} />;
});

// const Logo = ({ className, type }) => {
//   // additional mapping of class added here to allow the property to accept simple strings(like 'white','black') without exporting LogoType
//   return process.env.NEXT_PUBLIC_SITE_ID === '2' ? (
//     <HireDigital className={classNames(Styles.hdLogo, className, type)} />
//   ) : (
//     <ContentCo className={classNames(Styles.logo, className, type)} />
//   );
// };

Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

Logo.Type = {
  WHITE: Styles.white,
  BLACK: Styles.black,
  BLUE: Styles.blue,
  DARKBLUE: Styles.darkblue,
  DARKGRAY: Styles.darkgray,
};
export default Logo;
