import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Column.module.scss';

const Card = React.memo(({ className, children, ...props }) => {
  return (
    <div className={classNames(Styles.card, className)} {...props}>
      {children}
    </div>
  );
});

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Card.defaultProps = {
  className: '',
};
export default Card;
