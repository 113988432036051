import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Column.module.scss';

const Headline = React.memo(({ className, children, ...props }) => {
  return (
    <h3 className={classNames(Styles.headline, className)} {...props}>
      {children}
    </h3>
  );
});

Headline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Headline.defaultProps = {
  className: '',
};

export default Headline;
