export const getUrlParameter = (search, name) => {
  const updatedName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + updatedName + '=([^&#]*)');
  const results = regex.exec(search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const validatePhone = (phone) => {
  /*
  Simple phone validation 
  */
  if (/^[\+\d]?(?:[\+\d-.,#,*"\s()]*)$/.test(phone)) {
    return true;
  }
  return false;
};

export const validateEmail = (email) => {
  /*
  Simple email validation 
  */
  if (/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w.*)+$/.test(email)) {
    return true;
  }
  return false;
};

export const validateCorporateEmail = (email) => {
  if (
    /^([\w.+-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|icloud|me\.|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
};

export const validateCorporateAndValidEmail = (email) => {
  return {
    valid: validateEmail(email),
    corporate: validateCorporateEmail(email),
  };
};

export const getNameParts = (fullName) => {
  let firstName, lastName;
  if (fullName) {
    const fullNameArr = fullName.split(' ');
    firstName = fullNameArr.length > 1 ? fullNameArr.slice(0, -1).join(' ') : fullNameArr[0];
    lastName = fullNameArr.length > 1 ? fullName.split(' ').pop() : '';
  }
  return {
    firstName,
    lastName,
  };
};

export const getFullName = (first, last) => {
  let fullName;
  if (first || last) {
    fullName = first + ' ' + last;
  }
  return fullName;
};

export const identifyHubspotUser = (data) => {
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['identify', data]);
};
