import React from 'react';
import { stripIndent } from 'common-tags';

const SETTINGS = {
  app_id: `${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}`,
  custom_launcher_selector: '#intercom-chat',
  site_name: `${process.env.NEXT_PUBLIC_SITE_NAME}`,
};

export const sendEvent = (e = 'update', settings = SETTINGS) => {
  window && window.Intercom && window.Intercom(e);
  return e;
};

export const boot = () => {
  return sendEvent('boot');
};

export const script = () => {
  return (
    <script
      defer
      key={`intercom-script`}
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            window.intercomSettings = ${JSON.stringify(SETTINGS)};`,
      }}
    />
  );
};

export const oldScript = () => {
  return (
    <script
      defer
      key={`intercom-script`}
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            window.intercomSettings = ${JSON.stringify(SETTINGS)};
          (function(){
            var w=window;var ic=w.Intercom;
            if(typeof ic==="function"){
              ic('reattach_activator');
              ic('update',w.intercomSettings);}
            else{
              var d=document;var i=function(){i.c(arguments);};
              i.q=[];i.c=function(args){i.q.push(args);};
              w.Intercom=i;
              var l=function(){
                setTimeout(function() {
                var s=d.createElement('script');s.type='text/javascript';
                s.async=true;s.src='https://widget.intercom.io/widget/${
                  process.env.NEXT_PUBLIC_INTERCOM_APP_ID
                }';
                var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
              }, 2000);
              };
              if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `,
      }}
    />
  );
};
