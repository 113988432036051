import * as Sentry from '@sentry/node';
import * as device from '../../lib/device';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  dsn: `https://4255212c89ad425c999dbe65fd063782@sentry.io/1407159`,
});

const Helper = () => {
  return <> {device.load()}</>;
};

export default Helper;
