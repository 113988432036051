import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Styles from '../Header.module.scss';

const MobileMenu = ({ children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <React.Fragment>
      <div className={Styles.mobileMenu}>
        <div className={Styles.mobileButton} onClick={() => setVisible(!visible)}>
          {visible ? `Close` : `Menu`}
        </div>
        {visible && (
          <React.Fragment>
            <div className={Styles.mobileList}>{children}</div>
            {createPortal(
              <div className={Styles.mobileMask} onClick={() => setVisible(!visible)} />,
              document.body
            )}
          </React.Fragment>
        )}
      </div>
      <div className={Styles.defaultMenu}>{children}</div>
    </React.Fragment>
  );
};

export default React.memo(MobileMenu);
