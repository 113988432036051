import React from 'react';
import Styles from './Section.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Text from '../Text/Text';

const Section = ({
  children,
  className,
  style,
  title,
  subtitle,
  variation = Text.Variation.CENTER,
  titleClass,
  subTitleClass,
}) => {
  return (
    <div className={classNames(Styles.section, className)} style={style}>
      {title && (
        <Text
          element='h2'
          size={Text.Size.SUBHEADLINE}
          variation={variation}
          className={titleClass}>
          {title}
        </Text>
      )}
      {subtitle && (
        <Text
          element='p'
          size={Text.Size.SECTION_BODY}
          variation={variation}
          className={subTitleClass}>
          {subtitle}
        </Text>
      )}

      {title ? <div className={Styles.addMargin}>{children}</div> : children}
    </div>
  );
};

Section.Variation = Text.Variation;
Section.Styles = Styles;

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variation: PropTypes.string,
};

export default Section;
