import React from 'react';
import Link from '../../Link';
import Logo from '../../Logo/Logo';
import { TalentLinks } from './Common';
import MobileMenu from './MobileMenu';

import Styles from '../Header.module.scss';

const Default = ({ analyticsData }) => {
  return (
    <React.Fragment>
      <Link href='/' className={Styles.logoLink} title={`${process.env.NEXT_PUBLIC_SITE_NAME}`}>
        <Logo className={Styles.logo} />
        <h2 className={Styles.logoSuffix}>{`TALENT`}</h2>
      </Link>
      <MobileMenu>
        <TalentLinks>
          <Link
            className={Styles.primaryButton}
            title=''
            href={{
              pathname: '/talents/register',
              query: { ...analyticsData },
            }}>
            {`Apply As a Talent`}
          </Link>
        </TalentLinks>
      </MobileMenu>
    </React.Fragment>
  );
};

export default Default;
