import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import Logo from '../Logo/Logo';
import Button from '../Button/Button';
import Text from '../Text/Text';

//import { request } from '../../shared/apis/utils';
import { sendEvent } from '../../lib/gtm.js';
import { validateEmail, identifyHubspotUser } from '../../shared/helpers/forms';
import { submitHubspotForm } from '../Registration/common/helpers';
import { Site } from '../../shared/helpers/enum';
import { postInterest } from '../../shared/apis/interest';

import Styles from './Popup.module.scss';

const cookies = new Cookies();

const popupType = {
  FULL_PAGE_MODAL: 'full',
  BOTTOM_PAGE_MODAL: 'bottom',
};

const popupTypeKeys = {
  [popupType.FULL_PAGE_MODAL]: {
    cookieKey: 'cc-mat-full',
    formVariant: 'Desktop Full Popup',
  },
  [popupType.BOTTOM_PAGE_MODAL]: {
    cookieKey: 'cc-mat-bottom',
    formVariant: 'Bottom Mat',
  },
  mobile: { cookieKey: 'cc-mat-mobile', formVariant: 'Mobile Full Popup' }, // this is determine accordint to the current viewport size, cannot be set on PopupType
}; // Note! mobile is treated as a separate component(even though we just implemented this by css media query) thats why we have cookie for this so we could determine the previous state of this

const MAX_MOBILE_PX_WIDTH = 600; // based on old Mobile component, 599px and below is considered a mobile

const getMatTypeDependency = (type) => {
  const isMobile = window.innerWidth < MAX_MOBILE_PX_WIDTH;
  if (isMobile) {
    // regardless of what type the popup is, we should use the keys for mobile if the viewport is for mobile
    return popupTypeKeys.mobile;
  }
  return popupTypeKeys[type];
};

const Popup = ({ type, analyticsData, ...props }) => {
  const router = useRouter();
  const isDesktopBottom = type === popupType.BOTTOM_PAGE_MODAL;
  const isDesktopFull = type === popupType.FULL_PAGE_MODAL;

  const [showMat, setShowMat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [key, setKey] = useState(null);

  useEffect(() => {
    const key = getMatTypeDependency(type);
    setKey(key);
    const isShown = cookies.get(key.cookieKey) === 'true';

    if (!isShown) {
      setTimeout(() => {
        setShowMat(true);
      }, 90000); // 90000
    }
  }, [router, type]);
  const handleClose = () => {
    const cookieParams = { path: '/', maxAge: 432000 };
    cookies.set(key.cookieKey, 'true', cookieParams);
    setShowMat(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError(false);
  };

  const submitSubscribe = (data) => {
    const pageUrl = window.location.href;
    const pageName = document.title;

    cookies.set(key.cookieKey, 'true', { path: '/' });
    setLoading(true);

    data.siteId = Site.CONTENTCO.label;
    submitHubspotForm(process.env.NEXT_PUBLIC_NEWSLETTER_FORMID, pageUrl, pageName, {
      ...router.query,
      ...analyticsData,
      ...data,
      pageUrl,
      pageName,
      site: process.env.NEXT_PUBLIC_SITE_NAME,
    });
    //request.post(process.env.NEXT_PUBLIC_INTEREST_URL, data).then((response) => {
    postInterest(data).then((response) => {
      if (response.data) {
        setSubmitted(true);
        sendEvent({ event: 'GTM-Subscription' });
        setEmail('');
        setTimeout(() => {
          setSubmitted(false);
          setLoading(false);
          setShowMat(false);
        }, 5000); // wait 5 seconds, then reset to false
      }
    });
  };

  const handleSubmit = (event) => {
    const data = {
      email,
      join_list: true,
      form_type: 'Email Subscribe',
      form_variant: key.formVariant,
    };
    const isValid = validateEmail(email);
    if (isValid) {
      identifyHubspotUser(data);
      submitSubscribe(data);
    } else {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      {showMat && (
        <div
          className={classNames(Styles.container, {
            [Styles.fullContainer]: isDesktopFull,
            [Styles.bottomContainer]: isDesktopBottom,
          })}>
          <div
            className={classNames(Styles.modal, {
              [Styles.fullModal]: isDesktopFull,
              [Styles.bottomModal]: isDesktopBottom,
            })}>
            <div name='closePopup' className={Styles.closeButton} onClick={handleClose} />

            <div
              className={classNames(Styles.modalContent, {
                [Styles.modalContentFull]: isDesktopFull,
              })}>
              <div className={Styles.introWrapper}>
                <Logo className={Styles.matLogo} type={Logo.Type.WHITE} />
                <h2 className={Styles.headline}>
                  {`Learn from top insiders in digital marketing and growth today`}
                </h2>
                <p className={Styles.body}>
                  {`Receive insights on best practices and industry know-how.`}
                </p>
              </div>
              <div className={Styles.formWrapper}>
                {!submitted && (
                  <div
                    className={classNames(
                      Styles.formContainer,
                      isDesktopFull && Styles.formContainerFull
                    )}>
                    <div className={Styles.form}>
                      <input
                        className={Styles.input}
                        type='text'
                        placeholder='Email address'
                        name='email'
                        value={email}
                        onKeyPress={handleKeyPress}
                        onChange={handleEmailChange}
                      />
                      <Button
                        type={Button.Type.BLUE}
                        className={Styles.popButton}
                        onClick={handleSubmit}
                        style={{ flexShrink: 0 }}>
                        {loading ? (
                          <div className={Styles.loader}>
                            <div className={Styles.dot} />
                            <div className={Styles.dot} />
                            <div className={Styles.dot} />
                          </div>
                        ) : (
                          `Subscribe`
                        )}
                      </Button>
                    </div>

                    {error && (
                      <Text
                        size={Text.Size.BODY}
                        style={{ marginTop: '10px' }}
                        className={Styles.cSubscribeFormError}
                        variation={Text.Variation.WHITE}>{`Enter a valid email address`}</Text>
                    )}
                  </div>
                )}
                {submitted && (
                  <Text
                    style={{ marginTop: '10px' }}
                    size={Text.Size.BODY}
                    variation={Text.Variation.WHITE}>{`Thanks for subscribing.`}</Text>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Popup.type = popupType;
Popup.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object,
  type: PropTypes.string,
};

Popup.defaultProps = {
  type: popupType.BOTTOM_PAGE_MODAL,
};
export const PopupType = popupType;

export default Popup;
