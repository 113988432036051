import React from 'react';
import styles from './Workflow.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

//don't have state or refs, prefer normal functions (not arrow functions) over classes: airbnb styleguide
export default function WorkflowStep(props) {
  let contentClass = styles.stepContent;
  let itemClass = styles.stepItem;

  const stepContentType = {
    enterprise: styles.stepContentEnterprise,
  };

  if (props.type) {
    contentClass = classNames(contentClass, stepContentType[props.type]);
  }

  if (!props.desc) {
    contentClass = classNames(contentClass, styles.stepContentNoBorder);
  }

  return (
    <div className={itemClass}>
      <div className={styles.stepTimeline}>
        {props.image ? (
          // <img className="step__image" src={props.image} />
          // cant find step__image class
          <img src={props.image} />
        ) : (
          <p className={styles.stepOrder}>{props.order}</p>
        )}
      </div>
      <div className={contentClass}>
        <h3 className={styles.stepHeadline}>{props.title}</h3>
        <p className={styles.stepDesc}>{props.desc}</p>
      </div>
    </div>
  );
}

WorkflowStep.propTypes = {
  image: PropTypes.string,
  desc: PropTypes.string,
  title: PropTypes.string,
  order: PropTypes.string,
  type: PropTypes.string,
};
