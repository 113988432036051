import Styles from './Header.module.scss';

export const Types = {
  DARK: Styles.dark,
  LIGHT: Styles.light,
};

export const Variants = {
  DEFAULT: 1,
  ENTERPRISE: 2,
  INSIGHTS: 3,
  TALENT: 4,
};

export default null;
